<template>
  <div>
    <v-card elevation="0">
      <v-card-text class="scrollable-content">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="3" sm="4">
              <v-text-field
                ref="closing_date"
                v-model="closingDate"
                name="closing_date"
                type="date"
                label="現在の締め日"
                outlined
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <p class="ma-1">
            <v-icon class="pa-6"> mdi-arrow-down-bold </v-icon>
          </p>
          <v-row>
            <v-col cols="12" md="3" sm="4">
              <v-text-field
                ref="new_closing_date"
                v-model="newClosingDate"
                name="new_closing_date"
                :success="true"
                type="date"
                label="次の締め日"
                outlined
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              締め処理を行うと、締め日以前の契約情報は修正できません。
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" sm="4">
              <general-button btn_type="info" btn_block @click-event="closing"
                ><template v-slot:icon><v-icon left>mdi-lock</v-icon></template
                >締め処理</general-button
              >
            </v-col>
            <v-col cols="12" md="3" sm="4">
              <general-button btn_color="error" btn_block @click-event="back"
                ><template v-slot:icon
                  ><v-icon left>mdi-lock-open-variant</v-icon></template
                >締め日を戻す</general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import { useRouter } from "@/utils";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";

export default {
  setup(props, ctx) {
    const { router } = useRouter();
    const systemPropsRepository = repositoryFactory.get("systemProps");
    const state = reactive({
      closingDate: "",
      newClosingDate: "",
    });

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // 現在の締め日を取得
      const result = await systemPropsRepository
        .admin_get_key("closing_date")
        .then((response) => {
          if (response.data) {
            return response.data.value;
          } else {
            return "";
          }
        })
        .catch((error) => {
          throw (
            "ERROR:closing/index.vue/init systemPropsRepository.admin_get_key (" +
            error +
            ")"
          );
        });
      // YYYY-MM-DD形式の文字列が取得できる
      state.closingDate = result;
      // state.closingDateの翌月の末日を設定
      const next = new Date(state.closingDate);
      next.setDate(1);
      next.setMonth(next.getMonth() + 2);
      next.setDate(0);
      state.newClosingDate = utilDate.getDateFormatLong(next);

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    // 初期化する
    init();

    const updateClosingDate = async (closingDate) => {
      const param = {
        key: "closing_date",
        value: closingDate,
      };
      await systemPropsRepository
        .admin_update_key(param)
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:closing/index.vue/updateClosingDate systemPropsRepository.admin_update_key (" +
            error +
            ")"
          );
        });
    };

    // 締め処理を行う
    const closing = async () => {
      if (
        !window.confirm(
          "締め処理を行います。よろしいですか？\n" +
            state.newClosingDate +
            " に設定します。"
        )
      ) {
        return;
      }

      await updateClosingDate(state.newClosingDate);

      alert("締め処理を行いました。");
      init();
    };

    // 締め日を戻す
    const back = async () => {
      const backDate = new Date(state.closingDate);
      backDate.setDate(0);
      const newBackClosingDate = utilDate.getDateFormatLong(backDate);
      if (
        !window.confirm(
          "締め日を一か月戻します。よろしいですか？\n" +
            newBackClosingDate +
            " に設定します。"
        )
      ) {
        return;
      }

      await updateClosingDate(newBackClosingDate);

      alert("締め日を戻しました。");
      init();
    };

    return {
      setting,
      ...toRefs(state),
      closing,
      back,
    };
  },
};
</script>

<style scoped>
::v-deep #info_table thead {
  background-color: #e3f2fd !important;
}
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_content {
  min-width: 250px;
}
.new_anime {
  color: red;
}
</style>
